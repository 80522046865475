import React from 'react'
import {graphql} from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/header/Header'
import Info from 'gatsby-theme-psg/src/components/Info'
import Contact from 'gatsby-theme-psg/src/components/Contact'
import Section from 'gatsby-theme-psg/src/components/Section'
import Lead from '../components/header/Lead'

const InfoPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
      <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
        <Header>
          <Lead title={post.frontmatter.title} content={post.html} />
        </Header>
        <div className="section-info">
          <Section title="Algemene info" className="algemene-info">
            <Info category="Algemene info"/>
          </Section>
          <Section title="Vervoer" className="vervoer">
            <Info category="Vervoer"/>
          </Section>
          <Section title="Tickets" className="tickets">
            <Info category="Tickets"/>
          </Section>
          <section className="section-contact">
            <div className="container pb-5">
              <Contact />
            </div>
          </section>
        </div>
      </Layout>
  )
}

export default InfoPage

export const query = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
